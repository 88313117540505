<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-12 c g">
      <div class="card">
        <div class="card-header">
          <h4 class="text-center">
            <i class="fa fa-times"></i>
            {{ e("rejected-reports") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover custom-table">
              <thead>
                <th>
                  {{ e("number") }}
                </th>
                <th>
                  {{ e("date") }}
                </th>
                <th>
                  {{ e("time") }}
                </th>
                <th>
                  {{ e("serial-number") }}
                </th>
                <th>
                  {{ e("reason") }}
                </th>
              </thead>
              <tbody>
                <tr v-for="report in reports" :key="report._id">
                  <td>
                    {{ report.number }}
                  </td>
                  <td>
                    {{ report.date }}
                  </td>
                  <td>
                    {{ report.time }}
                  </td>
                  <td>
                    {{ report.serial_number }}
                  </td>
                  <td>
                    {{ e("reason-" + report.reason) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { BLink, BOverlay, BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BOverlay,
    BFormCheckbox,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      groups: [],
      reports: [],
    };
  },
  created() {
    var g = this;
    $.post(api + "/general/settings-groups/index", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.groups = JSON.parse(r);
        $.post(api + "/reports/rejected", {
          jwt: g.user.jwt,
        })
          .then(function (r) {
            g.reports = JSON.parse(r);
            g.loading = false;
          })
          .catch(function (e) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + JSON.stringify(e),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      })
      .catch(function (e) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: " + JSON.stringify(e),
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    e(d) {
      return e(d);
    },
  },
};
</script>